import React from "react";

import "./style.scss";
import dushiLogo from "../images/logo.png";
import appLogo from "../images/whatsapp.png";
import partnerLogo from "../images/Club-Sugar.jpg";
import bgGIF from "../images/mobile-bg.gif";
import bgVideo from "../video/aftermovie.mp4";
//import config from '../config/siteConfig';
//import { OutboundLink } from 'gatsby-plugin-google-analytics';

const firstLayerStyle = {
  zIndex: 1
};

const secondLayerStyle = {
  zIndex: 2
};

const thirdLayerStyle = {
  zIndex: 4
};

const mbBackground = {
  height: "100vh",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 3,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${bgGIF})`,
  opacity: 0.3
};

const Header = () => (
  <section className="v-header">
    <div className="fullscreen-wrap" style={firstLayerStyle}>
      <video
        id="vid"
        src={bgVideo}
        autoPlay={true}
        loop={true}
        muted={true}
      ></video>
      <div className="mb-background" style={mbBackground}></div>
    </div>
    <div className="header-overlay" style={secondLayerStyle}></div>
    <div
      className="container is-mobile has-text-centered"
      style={thirdLayerStyle}
    >
      <div className="columns is-vcentered">
        <div className="column">
          <img className="img" src={dushiLogo} alt="Dushi Yu"></img>
          <div id="eventFormButton" className="eventFormButton column">
            <a
              class="button is-medium"
              href="https://dushiyu.ticksz.com/"
              type="button"
            >
              KOOP JE TICKETS
            </a>
          </div>
          <div className="columns is-multiline">
            <div className="column is-full">
              <div className="is-flex is-horizontal-center">
                <article className="media">
                  <figure className="media-left">
                    <span className="icon is-medium">
                      <img src={appLogo} alt="..." />
                    </span>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <h1 className="title is-size-4">VIPLINE / APP</h1>
                      <p className="subtitle is-size-5">
                        <a href="tel:+31640344555"> +31640344555</a>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="column is-full">
              <div className="media-content is-flex is-horizontal-center">
                <figure className="image is-128x128 ">
                  <img src={partnerLogo} alt="..." />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Header;
