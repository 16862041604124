module.exports = {
    siteTitle: 'EVENTS // Dushi Yu',
    siteTitleAlt: 'Events by Dushi Yu', // This allows an alternative site title for SEO schema.
    publisher: 'Dushi Yu', // Organization name used for SEO schema
    siteDescription:
      'Your wish is our command!! Na veel vraag gaat het eindelijk gebeuren: DUSHI YU XXL editie! De meest geliefde caribische dansevenement. ',
    siteUrl: 'https://dushiyu-events.nl', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
    author: 'Chris Martis', // Author for RSS author segment and SEO schema
    authorUrl: '', // URL used for author and publisher schema, can be a social profile or other personal site
    userTwitter: '', // Change for Twitter Cards
    shortTitle: 'Dushi Yu // EVENTS', // Used for App manifest e.g. Mobile Home Screen
    shareImage: '/images/cover.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
    shareImageWidth: 1200, // Change to the width of your default share image
    shareImageHeight: 1200, // Change to the height of your default share image
    siteLogo: '/images/logo.png', // Logo used for SEO, RSS, and App manifest
    backgroundColor: '#e9e9e9', // Used for Offline Manifest
    themeColor: '#121212', // Used for Offline Manifest
   // currentEvent:'https://www.eventbrite.nl/e/tickets-mc-chriss-bday-young-ellens-dongo-irsais-tsean-59501881730',
  }